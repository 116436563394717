import * as React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby';

import { DefaultLayout } from '../components/layouts/default-layout';
import HomePageEditor from "../components/pages/home-page/home-page-editor";
import GraphicalArtsBlock from "../components/pages/home-page/graphical-arts-block";
import HelloBlock from "../components/pages/home-page/hello-block";
import GamedevBlock from "../components/pages/home-page/gamedev-block";
import PhotographyBlock from "../components/pages/home-page/photography-block";
import { repLinGradient } from "../components/utils";

const layoutWidth = 1280;
const layoutPadding = '1em';
const Wrapper = styled.div`
    /* > * + * {
        margin-top: 1em;
    } */
`;
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;

export default function Photography() {
    return <DefaultLayout>
        <Wrapper>
            <div style={{ backgroundColor: '#fff', position: 'relative',boxShadow: '0 0 .25em rgba(0, 0, 0, .5)' }}>
                <ContentBlock>
                    <div style={{ padding: '3em 0' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <HelloBlock />
                        </div>
                    </div>
                </ContentBlock>
            </div>

            <div style={{
                // backgroundColor: `hsl(60, 25%, 50%)`,
                background: repLinGradient(135, `hsl(60, 25%, 50%)`, `hsl(60, 25%, 50%, .95)`, '1em', '1.5em'),
                padding: '2em 0'
            }}>
                <ContentBlock>
                    <div style={{ backgroundColor: '#fff', padding: '2em', boxShadow: '0 0 .25em rgba(0, 0, 0, .5)' }}>
                        <PhotographyBlock />
                    </div>
                </ContentBlock>
            </div>
        </Wrapper>
    </DefaultLayout>
}
